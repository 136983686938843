import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Transition } from '@headlessui/react'

import ThemeToggle from './ThemeToggle'

const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  return (
    <nav className="nav sticky top-0 bg-white dark:bg-black bg-opacity-60 z-20">
      <div className="mx-auto max-w-7xl flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/" title="Go to homepage" className="text-4xl md:text-5xl">
            <span role="img" aria-label="Marius Küng">
              👨‍💻
            </span>
          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav className="hidden md:flex space-x-10">
          <Link
            className="text-xl font-bold hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
            to="/about/"
          >
            About
          </Link>
          <Link
            className="text-xl font-bold hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
            to="/uses/"
          >
            Uses
          </Link>
          {/* <Link
            className="text-xl font-bold hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
            to="/blog/"
          >
            Blog
          </Link> */}
          {/* <Link
            className="text-xl font-bold hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
            to="/places/"
          >
            Places
          </Link> */}
        </nav>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <ThemeToggle />
        </div>
      </div>
      <Transition
        show={isMobileNavOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {() => (
          <div
            // ref={ref}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-900 divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <span
                      className="text-4xl"
                      role="img"
                      aria-label="Marius Küng"
                    >
                      👨‍💻
                    </span>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-white dark:bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-gray-200 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
                    to="/about/"
                  >
                    About
                  </Link>
                  <Link
                    className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
                    to="/uses/"
                  >
                    Uses
                  </Link>
                  {/* <Link
                    className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
                    to="/blog/"
                  >
                    Blog
                  </Link> */}
                  {/* <Link
                    className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white dark:hover:text-gray-400"
                    to="/places/"
                  >
                    Places
                  </Link> */}
                </div>
                <div className="md:hidden mt-5">
                  <ThemeToggle />
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
