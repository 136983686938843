import React from 'react'
import { Link } from 'gatsby'
import Loadable from '@loadable/component'

const LoadableEmailLink = Loadable(() => import('./EmailLink'))

const FooterLinkStyles = 'hover:text-gray-600 transition'

const InternalFooterLink = ({ url, children }) => {
  return (
    <Link to={url} className={FooterLinkStyles}>
      {children}
    </Link>
  )
}
const ExternalFooterLink = ({ url, children }) => {
  return (
    <a href={url} className={FooterLinkStyles} rel="noopener noreferrer">
      {children}
    </a>
  )
}

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer className="border-t border-solid border-gray-200 dark:border-gray-400 pt-8 mt-40 mb-20 px-4 max-w-4xl text-gray-500 lg:px-0 sm:mx-auto">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <p className="sm:col-span-2">&copy; {currentYear} Marius Küng</p>

        <div className="flex flex-col space-y-4 mt-5 sm:mt-0">
          <InternalFooterLink url={'/'}>Home</InternalFooterLink>
          <InternalFooterLink url={'/about'}>About</InternalFooterLink>
          <InternalFooterLink url={'/uses'}>Uses</InternalFooterLink>
        </div>

        {/* <div className="flex flex-col space-y-4">
          <InternalFooterLink url={'/uses'}>Uses</InternalFooterLink>
          <InternalFooterLink url={'/blog'}>Blog</InternalFooterLink>
          <InternalFooterLink url={'stack'}>My Stack</InternalFooterLink>
        </div>
         */}

        <div className="flex flex-col space-y-4">
          <LoadableEmailLink />
          <ExternalFooterLink url={'https://twitter.com/mariuskueng'}>
            Twitter
          </ExternalFooterLink>
          <ExternalFooterLink url={'https://github.com/mariuskueng'}>
            Github
          </ExternalFooterLink>
          <ExternalFooterLink
            url={'https://www.linkedin.com/in/marius-k%C3%BCng-bb2605116/'}
          >
            LinkedIn
          </ExternalFooterLink>
        </div>
      </div>
    </footer>
  )
}

export default Footer
